<template>
  <div class="userCollect">
    <div class="list">
      <div class="video_list clearfix">
        <!-- wow animate__animated animate__fadeInUp -->
        <div
          class="fl video_li"
          :style="{ animationDelay: 0.1 * index + 's' }"
          v-for="(item, index) in list"
          :key="index"
          @click="toDetail(item.video_id)"
        >
          <div class="video_li_box">
            <div class="reading_thumb">
              <img
                v-lazy="item.videoinfo.thumb"
                :key="item.videoinfo.thumb"
                alt=""
              />
            </div>
            <!-- <img src="../../assets/images/image05_mytj 12x.png" alt=""> -->
            <!-- <div class="video_li_mask"></div>
                        <div class="video_li_play"><img src="../../assets/images/icon_play@2x.png" alt=""></div> -->
            <div
              class="userFavorite_li_del"
              v-if="is_benren == 1"
              @click.stop="videoDel(item.id)"
            >
              移除
            </div>
          </div>
          <!-- <div class="video_li_name">{{item.videoinfo.title}}</div>
                    <div class="video_li_reading clearfix">
                        <div class="fl">{{item.videoinfo.level_name}}丨{{item.videoinfo.font_style_name}}</div>
                        <div class="fr clearfix">
                            <div class="fl clearfix video_li_reading_k">
                                <img class="fl" src="../../assets/images/yulan.svg" alt="">
                                <span class="fl">{{item.videoinfo.browse_num}}</span>
                            </div>
                            <div class="fl clearfix">
                                <img class="fl" src="../../assets/images/shoucang.svg" alt="">
                                <span class="fl">{{item.videoinfo.collect_num}}</span>
                            </div>
                        </div>
                    </div> -->
          <div class="video_li_reading clearfix">
            <div class="fl">
              <div class="video_li_name">{{ item.videoinfo.title }}</div>
            </div>
            <div class="fr clearfix">
              <div class="fl clearfix video_li_reading_k">
                <img class="fl" src="../../assets/images/yulan.svg" alt="" />
                <span class="fl">{{ item.videoinfo.browse_num || 0 }}</span>
              </div>
            </div>
            <div class="clearfix jindu"><el-progress :percentage="item.progress==0?1:item.progress" /></div>
          </div>
        </div>
      </div>
      <el-empty
        v-if="!list || list.length <= 0"
        description="暂无数据"
      ></el-empty>
      <page v-if="total > 12" :page="page" :limit="limit" :total="total"></page>
    </div>
  </div>
</template>

<script>
const format = (percentage) => (percentage === 100 ? "Full" : `${percentage}%`);
// import { WOW } from 'wowjs'
import page from "../../components/page.vue";
export default {
  name: "userCollect",
  components: {
    page,
  },
  data() {
    return {
      // 学习记录
      list: [],
      // 全部作业
      worklist: [],

      // 分页
      total: 0, //总数
      page: 1, //页数
      limit: 12, //每页个数

      // 收藏参数
      type: "", //类型
      collId: "", //收藏id

      is_benren: 1,

      tabindex: 1,
    };
  },
  mounted() {
    this.$parent.tab = 2;
    this.$parent.tabIndex = 4;
    this.$parent.$parent.routerIndex = 0;
    this.$parent.$parent.showIndex = 1;
    this.is_benren = this.$parent.is_benren;
    // new WOW().init();
    this.listFun();
  },
  methods: {
    listFun() {
      var that = this;
      this.$api.POST(
        this.$face.video_viewing_recordIndex,
        {
          user_id: this.$route.query.userId || "",
          page: this.page,
          limit: this.limit,
        },
        function (res) {
          console.log(res);
          that.list = res.data.list;
          that.total = res.data.total;
        }
      );
    },
    // 作业记录
    workFun(type) {
      var that = this;
      this.$api.POST(
        this.$face.videTask,
        {
          user_id: this.$route.query.userId || "",
          page: this.page,
          limit: this.limit,
          type: type,
        },
        function (res) {
          console.log(res);
          that.worklist = res.data.list;
          that.total = res.data.total;
        }
      );
    },

    //移除学习记录
    videoDel(id) {
      var that = this;
      this.$api.POST(
        this.$face.video_viewing_recordDel,
        {
          id: id,
        },
        function (res) {
          that.$utile.prompt("success", "移除成功");
          that.listFun();
        }
      );
    },

    //查看详情
    toDetail(id) {
      this.$router.push({
        name: "videoDetail",
        query: {
          id: id,
        },
      });
    },
    // 左侧导航选中
    clickActive(type) {
      console.log("type", type);
      this.tabindex = type;
      this.page = 1;
      if (type == 1) {
        this.listFun();
        console.log("type=1", type);
      } else if (type == 2 || type == 3) {
        this.workFun(type);

        console.log("type=2", type);
      }
    },
  },
};
</script>

<style scoped>
.demo-progress .el-progress--line {
  margin-bottom: 15px;
  width: 100%;
}
.userNewsLeft {
  background-color: #fff;
  border-radius: 10px;
  width: 15%;
  float: left;
}

.userNewsLeft ul {
  padding: 37px 0 0;
  overflow: hidden;
}

.userNewsLeft li {
  font-size: 16px;
  color: #333;
  padding-left: 37px;
  margin-bottom: 37px;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  position: relative;
}

.userNewsLeft li::before {
  display: block;
  width: 6px;
  height: 20px;
  content: " ";
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
}

.userNewsLeft li.active::before {
  background-color: #5957ff;
}

.userNewsLeft li.active {
  color: #5957ff;
}

.userNewsRight {
  width: 78%;
  background: #fff;
  border-radius: 10px;
  float: right;
  padding: 15px 2% 0;
  min-height: 500px;
  position: relative;
}

.userCollect {
  padding-top: 26px;
  height: auto;
  overflow: hidden;
}

.list {
  padding-bottom: 99px;
}

/* 视频教程 */
.video_list {
  margin: 0 -13.33px 24px;
}

.video_li {
  width: 330px;
  margin: 0 13.33px 24px;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
}

.video_li_box {
  width: 100%;
  height: 198px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.video_li_box > img {
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.video_li_mask {
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
  /* opacity: 0; */
  z-index: 33;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: default;
}

.video_li_play {
  position: absolute;
  top: 140%;
  left: 50%;
  z-index: 101;
  margin-left: -37.5px;
  margin-top: -37px;
  width: 74px;
  height: 75px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.video_li:hover .video_li_mask {
  height: 100%;
}

.video_li:hover .video_li_play {
  top: 50%;
  transition-delay: 0.3s;
}

/* .video_li:hover .video_li_box img{
    transform: scale(1.08)
} */

.video_li_name {
  /* height: 52px;
    line-height: 49px; */
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  width: 230px;
  overflow: hidden;
  /* 第二步：让文本不会换行， 在同一行继续 */
  white-space: nowrap;
  /* 第三步：用省略号来代表未显示完的文本 */
  text-overflow: ellipsis;
}

.video_li_reading {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  margin-top: 10px;
  letter-spacing: 0px;
  color: #999999;
}

.video_li_reading img {
  width: auto;
  height: 20px;
  margin-right: 4px;
}

.video_li_reading span {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #999999;
}

.video_li_reading_k {
  margin-right: 16px;
}

.userFavorite_li_del {
  width: 43px;
  height: 26px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 26px;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 15px;
  right: 16px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.video_li:hover .userFavorite_li_del {
  opacity: 1;
}

.video_li .video_li_box {
  height: 198px;
}

.video_li .reading_thumb {
  position: relative;
  height: 198px;
  transition: all 0.3s ease-in-out;
}

.video_li .reading_thumb > img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: auto;
}

.video_li:hover .reading_thumb {
  transform: scale(1.08);
}
.jindu{ padding-top: 15px; clear: both;}
</style>
